import styles from "./GameScreen.module.css";

import { useSelector } from "react-redux";
import GameLeaderboard from "../../components/GameLeaderboard/GameLeaderboard";
import Game from "../../components/Game/Game";
import UserHud from "../../components/UserHud/UserHud";

export default function GameScreen() {
  let gameState = useSelector((state) => state.game.gameState);

  //TODO:: Long-term this should be a loading bar or else we get white screen as we wait
  //Additional TODO:: Lazy loaded images should have a better transition in when they appear.

  return gameState.me ? (
    <div className={styles.GameScreen}>
      <GameLeaderboard />
      <UserHud />
      <Game />
    </div>
  ) : (
    <div className={styles.GameScreen}></div>
  );
}
