import {
  AbilityConstants,
  DynamicInputs,
} from "../../../../constants/AbilitySystemConstants";
import qImage from "../../../../assets/sprites/q.png";
import eImage from "../../../../assets/sprites/e.png";
import rImage from "../../../../assets/sprites/r.png";



const jirqoAbilities = {
  [AbilityConstants.ATTACK]: {
    cooldown: 1000,
  },
  [AbilityConstants.Q]: {
    cooldown: 8000,
    name: "Poison Arrow",
    description: "Shoot a poison arrow",
    image: qImage,
  },
  [AbilityConstants.E]: {
    cooldown: 12000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Glue Arrow",
    description: "Shoot a glue arrow",
    image: eImage,
  },
  [AbilityConstants.R]: {
    cooldown: 50000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Poison Arrow",
    description: "Shoot a bomb arrow",
    image: rImage,
  },
};
export default jirqoAbilities;
