
import styles from "./UserHud.module.css"
import Ability from "./Ability/Ability";
import StatusBar from "./StatusBar/StatusBar";
import Map from "./Map/Map";
import attackIcon from "./sprites/attack.png"
import qIcon from "./sprites/q1.png";
import qIcon2 from "./sprites/Version1/q.png"
import eIcon from "./sprites/e.png";
import eIcon2 from "./sprites/Version1/e.png";
import rIcon from "./sprites/r.png";
import rIcon2 from "./sprites/Version1/r.png";
import { AbilityConstants }  from "../../constants/AbilitySystemConstants.js"

import { useSelector } from "react-redux";

export default function UserHud() {

    let gameState = useSelector((state) => state.game.gameState);
    let xPos = gameState.me.pos.x;
    let yPos = gameState.me.pos.y;
    const health = gameState.me.health;

  return (
    <div className={styles.wrapper}>
      <Ability
        awayNum={0}
        imageSource={attackIcon}
        text={"Attack"}
        ability={AbilityConstants.ATTACK}
      />
      <Ability
        awayNum={1}
        imageSource={qIcon2}
        text={"Q"}
        ability={AbilityConstants.Q}
      />
      <Ability
        awayNum={2}
        imageSource={eIcon2}
        text={"E"}
        ability={AbilityConstants.E}
      />
      <Ability
        awayNum={3}
        imageSource={rIcon2}
        text={"R"}
        ability={AbilityConstants.R}
      />
      <StatusBar val={Math.floor(health)} maxVal={100} />
      <StatusBar isMana val={124} maxVal={310} />
      <Map xPos={xPos} yPos={yPos} />
    </div>
  );
}
