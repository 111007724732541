export const ORIGINAL_COLOR = "ORIGINAL_COLOR";

export const damageColorsAnimation = {
  animation: [
    {
      name: "body",
      duration: 100,
      fillStyle: "#FFFFFF",
      delay: 0,
    },
    {
      name: "body",
      duration: 0,
      fillStyle: "#FF0000",
      delay: 100,
    },
    {
      name: "body",
      duration: 500,
      fillStyle: ORIGINAL_COLOR,
      delay: 100,
    },
  ],
};


