import BiomeConstants from "../../constants/MapConstants"

const biomeGrid = [
  [
    BiomeConstants.FOREST,
    BiomeConstants.FOREST,
    BiomeConstants.OCEAN,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
  ],
  [
    BiomeConstants.FOREST,
    BiomeConstants.FOREST,
    BiomeConstants.OCEAN,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
  ],
  [
    BiomeConstants.OCEAN,
    BiomeConstants.OCEAN,
    BiomeConstants.OCEAN,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
  ],
  [
    BiomeConstants.SNOW,
    BiomeConstants.SNOW,
    BiomeConstants.RIVER,
    BiomeConstants.RIVER,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
  ],
  [
    BiomeConstants.SNOW,
    BiomeConstants.SNOW,
    BiomeConstants.SNOW,
    BiomeConstants.RIVER,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
    BiomeConstants.DESERT,
  ],
  [
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.RIVER,
    BiomeConstants.RIVER,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
    BiomeConstants.PLAINS,
  ],
  [
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.SNOW,
    BiomeConstants.RIVER,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
  ],
  [
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.MOUNTAIN,
    BiomeConstants.SNOW,
    BiomeConstants.SNOW,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
    BiomeConstants.BEACH,
  ],
];

export default biomeGrid;