import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

export const fylgjaInfo = {
  drawing: {
    shapes: [
      {
        name: "handle",
        type: "rectangle",
        x: -40,
        y: 20,
        width: 90,
        height: 5,
        fillStyle: "#3A0D0D",
        lineWidth: 8,
        strokeStyle: "#000000",
        rotation: (Math.PI * -90) / 180,
      },
      {
        name: "hammerhead",
        type: "polygon",
        points: [
          { x: 40, y: 40 },
          { x: 45, y: 60 },
          { x: 65, y: 60 },
          { x: 70, y: 40 },
          { x: 55, y: 22 },
        ],
        xTrans: 20,
        yTrans: -20,
        fillStyle: "gray",
        lineWidth: 8,
        strokeStyle: "#000000",
        rotation: (Math.PI * -90) / 180,
      },
      {
        name: "arm1",
        type: "circle",
        radius: 6,
        fillStyle: "#D9D9D9",
        lineWidth: 8,
        strokeStyle: "#000000",
        x: 30,
        y: 0,
        rotation: (Math.PI * 50) / 180,
      },
      {
        name: "arm2",
        type: "circle",
        radius: 6,
        fillStyle: "#D9D9D9",
        lineWidth: 8,
        strokeStyle: "#000000",
        x: 30,
        y: 0,
        rotation: (Math.PI * -50) / 180,
      },
      {
        name: "body",
        type: "circle",
        radius: 25,
        fillStyle: "#D9D9D9",
        lineWidth: 8,
        strokeStyle: "#000000",
        x: 0,
        y: 0,
        rotation: 0,
      },
    ],
  },
  animations: {
    [ObjectStateConstants.ATTACKING]: {
      animation: [
        // Wind Up Animation
        {
          name: "arm1",
          duration: 500,
          rotation: (-45 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 0,
        },
        {
          name: "arm2",
          duration: 500,
          rotation: (-45 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 0,
        },
        {
          name: "handle",
          duration: 500,
          rotation: (-45 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 0,
        },
        {
          name: "hammerhead",
          duration: 500,
          rotation: (-45 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 0,
        },
        // Striking Animation
        {
          name: "arm1",
          duration: 200,
          rotation: (160 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 500,
        },
        {
          name: "arm2",
          duration: 200,
          rotation: (160 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 500,
        },
        {
          name: "handle",
          duration: 200,
          rotation: (160 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 500,
        },
        {
          name: "hammerhead",
          duration: 200,
          rotation: (160 * Math.PI) / 180,
          x: 0,
          y: 0,
          delay: 500,
        },
        // Return Animation
        {
          name: "arm1",
          duration: 500,
          rotation: 0,
          x: 0,
          y: 0,
          delay: 700,
        },
        {
          name: "arm2",
          duration: 500,
          rotation: 0,
          x: 0,
          y: 0,
          delay: 700,
        },
        {
          name: "handle",
          duration: 500,
          rotation: 0,
          x: 0,
          y: 0,
          delay: 700,
        },
        {
          name: "hammerhead",
          duration: 500,
          rotation: 0,
          x: 0,
          y: 0,
          delay: 700,
        },
      ],
    },
  },
  abilities: {
    [AbilityConstants.ATTACK]: {
      cooldown: 2000,
    },
    [AbilityConstants.Q]: {
      cooldown: 2000,
    },
    [AbilityConstants.E]: {
      cooldown: 2000,
    },
    [AbilityConstants.R]: {
      cooldown: 2000,
    },
  },
};

export default fylgjaInfo;
