
import mapManager from "../model/MapManager";
import BiomeConstants from "../../constants/MapConstants";

const biomeLength = 1050;

function renderBiome(canvasElement, biomeX, biomeY){
    let colorMapping = {
      [BiomeConstants.PLAINS]: "#7CAB4C",
      [BiomeConstants.FOREST]: "#577C31",
      [BiomeConstants.SNOW]: "#BDBBB3",
      [BiomeConstants.MOUNTAIN]: "#C8CAC6",
      [BiomeConstants.OCEAN]: "#377280",
      [BiomeConstants.RIVER]: "#377280",
      [BiomeConstants.BEACH]: "#9FD2BF",
      [BiomeConstants.DESERT]: "#DFC8A0",
    };

    const ctx = canvasElement.getContext("2d");
    
    let biomeType = mapManager.getBiome(biomeX, biomeY)
    let biomeColor = colorMapping[biomeType]



    //Background color
    
    ctx.fillStyle = biomeColor;
    ctx.fillRect(
      biomeX * biomeLength,
      biomeY * biomeLength,
      biomeLength,
      biomeLength
    );

    //Background grid
    for (
      let i = 0 + biomeX * biomeLength;
      i < biomeLength + biomeX * biomeLength;
      i += 30
    ) {
      ctx.strokeStyle = "rgba(0, 0, 0, 0.4)";
      ctx.lineWidth = 0.3;
      ctx.beginPath();
      ctx.moveTo(i, biomeY * biomeLength);
      ctx.lineTo(i, biomeLength + + biomeY * biomeLength);
      ctx.stroke();
    }
    for (
      let i = 0 + biomeY * biomeLength;
      i < biomeLength + biomeY * biomeLength;
      i += 30
    ) {
      ctx.beginPath();
      ctx.moveTo(biomeX * biomeLength , i);
      ctx.lineTo(biomeLength + biomeX * biomeLength, i);
      ctx.stroke();
    }
}

function renderOutBounds(canvasElement, biomeX, biomeY) {
  let colorMapping = {
    [BiomeConstants.PLAINS]: "#3e5626",
    [BiomeConstants.FOREST]: "#2c3e19",
    [BiomeConstants.SNOW]: "#5f5e5a",
    [BiomeConstants.MOUNTAIN]: "#646563",
    [BiomeConstants.OCEAN]: "#1c3940",
    [BiomeConstants.RIVER]: "#1c3940",
    [BiomeConstants.BEACH]: "#506960",
    [BiomeConstants.DESERT]: "#706450",
  };

  const ctx = canvasElement.getContext("2d");

  let biomeType = mapManager.closestInBoundsBiome(biomeX, biomeY);
  let biomeColor = colorMapping[biomeType];

  //Background color
  ctx.fillStyle = biomeColor;
  ctx.fillRect(
    biomeX * biomeLength,
    biomeY * biomeLength,
    biomeLength,
    biomeLength
  );

  //Background grid
  for (
    let i = 0 + biomeX * biomeLength;
    i < biomeLength + biomeX * biomeLength;
    i += 30
  ) {
    ctx.strokeStyle = "rgba(0, 0, 0, 0.4)";
    ctx.lineWidth = 0.3;
    ctx.beginPath();
    ctx.moveTo(i, biomeY * biomeLength);
    ctx.lineTo(i, biomeLength + +biomeY * biomeLength);
    ctx.stroke();
  }
  for (
    let i = 0 + biomeY * biomeLength;
    i < biomeLength + biomeY * biomeLength;
    i += 30
  ) {
    ctx.beginPath();
    ctx.moveTo(biomeX * biomeLength, i);
    ctx.lineTo(biomeLength + biomeX * biomeLength, i);
    ctx.stroke();
  }
}



function renderEnvironment(canvasElement, player){
  let myPos = player.pos;
  let xIndex = Math.floor(myPos.x/ biomeLength)
  let yIndex = Math.floor(myPos.y / biomeLength);
  


  for (let xShift = -1; xShift < 2; xShift++ ){
    for (let yShift = -1; yShift < 2; yShift++) {
      let newX = xIndex + xShift;
      let newY = yIndex + yShift;
      if (mapManager.inBounds(newX, newY)){
        renderBiome(canvasElement, newX, newY);
      } else {
        renderOutBounds(canvasElement, newX, newY);
      }
    }
  }
    

  

}

export default renderEnvironment