const circleTrailDrawInfo = {
  shapes: [
    {
      name: "circle_base",
      type: "circle",
      radius: 3,
      fillStyle: "#000000FF",
      lineWidth: 0,
      strokeStyle: "#000000FF",
      x: 0,
      y: 0,
      rotation: (0 * Math.PI) / 180,
    },
  ],
};

export default circleTrailDrawInfo
