import circleTrailDrawInfo from "./circleTrailDrawInfo";
import circleTrailAnimation from "./circleTrailAnimation";
import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

const circleTrailInfo = {
  drawing: circleTrailDrawInfo,
  animations: {
    [ObjectStateConstants.ACTIVE]: circleTrailAnimation,
  },
};

export default circleTrailInfo;
