import { UPDATE_ABILITY_COOLDOWN, UPDATE_PLAYER_NAME } from "../constants/userData-types.js";

export function updateCooldown(ability, champion) {
  return {
    type: UPDATE_ABILITY_COOLDOWN,
    ability: ability,
    champion: champion,
  };
}

export function updatePlayerName(playerName){
  return {
    type: UPDATE_PLAYER_NAME,
    playerName: playerName,
  }
}

