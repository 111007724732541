export const ObjectTypes = {
  PLAYER: "PLAYER",
  PROJECTILE: "PROJECTILE",
  TRAIL: "TRAIL",
};

export const ObjectStateConstants = {
  IDLE: "IDLE",
  ACTIVE: "ACTIVE",
  ATTACKING: "ATTACKING",
  CASTING_Q: "CASTING_Q",
  CASTING_E: "CASTING_E",
  CASTING_R: "CASTING_R",
  STUNNED: "STUNNED",
  DEATH: "DEATH",
};