import { AbilityConstants } from "../../constants/AbilitySystemConstants.js";
import allChampions from "../../game/champions/champions.js";


import { UPDATE_ABILITY_COOLDOWN, UPDATE_PLAYER_NAME } from "../constants/userData-types.js";

const initialState = {
  playerName: "",
  cooldowns: {
    [AbilityConstants.ATTACK]: 0,
    [AbilityConstants.Q]: 0,
    [AbilityConstants.E]: 0,
    [AbilityConstants.R]: 0,  
    },
};

export default function userDataReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ABILITY_COOLDOWN:
      const cooldownTime = allChampions[action.champion].abilities[action.ability].cooldown
      const curTime = Date.now()

      let newCooldowns = state.cooldowns; 
      if (curTime >= state.cooldowns[action.ability]){
        newCooldowns = {...state.cooldowns, [action.ability] : curTime + cooldownTime }
      }

      return {
        ...state,
        cooldowns: newCooldowns,
      };
    case UPDATE_PLAYER_NAME: 
      return {
        ...state,
        playerName: action.playerName,
      }
    default:
      return state;
  }
}