import { combineReducers } from "redux";
import gameStateReducer from "./gameState-reducer";
import userInputReducer from "./userInput-reducer";
import mobaReducer from "./moba-reducer";
import userDataReducer from "./userData-reducer";
import championSelectReducer from "./championSelect-reducer";


const appReducer = combineReducers({
  moba: mobaReducer,
  game: gameStateReducer,
  userInput: userInputReducer,
  userData: userDataReducer,
  championSelect: championSelectReducer,
});

export default appReducer;