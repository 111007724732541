const ChampionNames = {
  NO_CHAMPION: "NO_CHAMPION",
  FYLGJA: "FYLGJA",
  REMI: "REMI",
  LUCCA_SPETUM: "LUCCA_SPETUM",
  LUCCA_BOAR: "LUCCA_BOAR",
  JIRQO: "JIRQO",
  FUTURE_CHAMPION_1: "FUTURE_CHAMPION_1",
  FUTURE_CHAMPION_2: "FUTURE_CHAMPION_2",
  FUTURE_CHAMPION_2: "FUTURE_CHAMPION_3",
};

export default ChampionNames;
