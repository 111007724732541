import styles from "./ChampionDescription.module.css";
import victorious from "../../../../assets/victorious.jpg";
import ChampionBio from "./ChampionBio/ChampionBio";
import { useSelector } from "react-redux";
import allChampions from "../../../../game/champions/champions";
import ChampionNames from "../../../../constants/ChampionNames";


function ChampionDescription() {
  const selectedChampion = useSelector((state) => state.championSelect.selectedChampion);
  const hoveredChampion = useSelector((state) => state.championSelect.hoveredChampion);

  function getChampionInfo(){
    if (selectedChampion != ChampionNames.NO_CHAMPION){
      return allChampions[selectedChampion];
    }
    else {
      return allChampions[hoveredChampion];
    }
  }

  const champInfo = getChampionInfo();
  const myImage = champInfo.image;
  
  return (
    <div className={styles.container}>
      <div className={styles.bioFlexbox}>
        <img src={myImage} className={styles.championImage}></img>
        <div className={styles.championBio}><ChampionBio /></div>
      </div>
    </div>
  );
}

export default ChampionDescription;
