const windUpDuration = 50;

const attackAnimation = {
  animation: [
    // Wind Up Animation
    {
      name: "recurveBow",
      duration: windUpDuration,
      rotation: (-60 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "drawString",
      duration: windUpDuration,
      rotation: (-60 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "arm1",
      duration: windUpDuration,
      rotation: (-60 * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "arm2",
      duration: windUpDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: -30,
      delay: 50,
    },
  ],
};

export default attackAnimation;
