import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

import jirqoImage from "../../../assets/bard.png";
import jirqoDrawInfo from "./drawings/jirqoDrawInfo";
import attackAnimation from "./animations/attackAnimation";
import eAnimation from "./animations/eAnimation";
import deathAnimation from "../damageAnimations/deathAnimation";
import jirqoAbilities from "./abilities/jirqoAbilities";


export const jirqoInfo = {
  drawing: jirqoDrawInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: attackAnimation,
    [ObjectStateConstants.CASTING_Q]: { animation: [] },
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: eAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH]: deathAnimation
  },
  abilities: jirqoAbilities,
  image: jirqoImage,
  name: "Jirqo",
  biography: `With a single arrow, Jirqo brought the mighty Genghis Khan crashing from his horse. 
   When the Khan gathered the remnants of his defeated clan and demanded to know the archer's name,
    Jirqo stepped forward, unflinching. His boldness won him the Khan's respect,
     and a place as the Khan's highest ranking general.`,
};

export default jirqoInfo;
