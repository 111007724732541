import drawShapeWithAnimators from "./drawShapeWithAnimators";

import { playerAnimationManager } from "../model/AnimationManager";
import { statusDrawInfo } from "../champions/statusEffects/statusEffects";
import allChampions from "../champions/champions";


function alignCanvasWithPlayer(ctx, player){
  ctx.translate(player.pos.x, player.pos.y);
  ctx.rotate(player.rotation);
}

function unalignCanvasFromPlayer(ctx, player){
  ctx.rotate(-player.rotation);
  ctx.translate(-player.pos.x, -player.pos.y);
}

function drawHealthBar(ctx, player) {
  //Healthbar
  let healthX = player.pos.x - 28;
  let healthY = player.pos.y - 65;
  let healthWidth = 50;
  let healthHeight = 2.5;
  
  let activeHealthWidth = Math.max((player.health / 100) * healthWidth, 0)
  ctx.fillStyle = "gray";
  ctx.fillRect(healthX, healthY, healthWidth, healthHeight);
  ctx.fillStyle = "red";
  ctx.fillRect(
    healthX,
    healthY,
    activeHealthWidth,
    healthHeight
  );
}

function drawChampionWithAnimators(ctx, player) {
  //Retrieve relevant information.
  const championInfo = allChampions[player.champion];
  const animator = playerAnimationManager.getAnimator(player.id);

  //Center & Rotate canvas on player location.
  alignCanvasWithPlayer(ctx, player);
  //Draw Champion
  for (let shape of championInfo.drawing.shapes) {
    drawShapeWithAnimators(ctx, shape, animator);
  }
  //Draw Status Effects associated to champion.
  if (statusDrawInfo[player.state]) {
    for (let shape of statusDrawInfo[player.state].drawing) {
      drawShapeWithAnimators(ctx, shape, null);
    }
  }
  //Undo centering & rotation from player location.
  unalignCanvasFromPlayer(ctx, player);
}

export default function renderChampion(ctx, player) {
  drawHealthBar(ctx, player)
  drawChampionWithAnimators(ctx, player)
}
