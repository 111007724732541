
function alphaHexToDecimal(hex){
  let rawAlpha = parseInt(hex,16)
  let alpha = Math.round(rawAlpha/255*100)/100;
  return alpha
}

function alphaDecimalToHex (decimal){
  let rawAlpha = decimal * 255;
  let hex = Math.round(rawAlpha).toString(16).padStart(2,"0")
  return hex
}


export function hexToRGBA(hex){
    //We assume it's a 7 character string
    // #FFFFFF
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);
    let a = 1.0
    if(hex.length >= 9){
      a = alphaHexToDecimal(hex.substring(7,9));
    }
    return {r,g,b,a}
}

export function RGBAtoHex({r,g,b,a}){
    let hexadecimal = "#"
    
    r = Math.round(r)
    let hexR = r.toString(16);
    if (hexR.length % 2 > 0) {
        hexR = "0" + hexR;
    }
    hexadecimal += hexR;

    g = Math.round(g);
    let hexG = g.toString(16);
    if (hexG.length % 2 > 0) {
      hexG = "0" + hexG;
    }
    hexadecimal += hexG;

    b = Math.round(b);
    let hexB = b.toString(16);
    if (hexB.length % 2 > 0) {
      hexB = "0" + hexB;
    }
    hexadecimal += hexB;

    let hexA = alphaDecimalToHex(a);
    hexadecimal += hexA;


    return hexadecimal;
}

export function copyTransferRGBAValues(start, end){
    end.r = start.r;
    end.g = start.g;
    end.b = start.b;
    end.a = start.a;
}