
//Use variable bc positions can be auto-calculated
//Based off the root handle.


let handleSize = 8; //CHANGED
let handleLongSize = 128; //CHANGED
let handleX = -64; //CHANGED
let handleY = 64; //CHANGED

let guardHeight = 32; //CHANGED
let guardWidth = 4; //CHANGED
let guardX = handleX + handleLongSize //CHANGED
let guardY = handleY - (guardHeight/2) + (handleSize/2) //CHANGED

let connectX = handleX + handleLongSize; //CHANGED
let connectY = handleY; //CHANGED
let connectLength = 80; //CHANGED

let spearX = connectX + connectLength; //CHANGED
let spearY = connectY + (handleSize/2) //CHANGED
let spearWidth = 20; //CHANGED
let spearLength = 56; //CHANGED

const boarspear = [
  {
    name: "Bhandle",
    type: "rectangle",
    x: handleX,
    y: handleY,
    width: handleLongSize,
    height: handleSize,
    fillStyle: "#594031",
    lineWidth: 6,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bguard",
    type: "rectangle",
    x: guardX,
    y: guardY,
    width: guardWidth,
    height: guardHeight,
    fillStyle: "#292D2D",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bspear",
    type: "polygon",
    points: [
      { x: spearX, y: spearY },
      { x: spearX - spearLength, y: spearY + spearWidth / 2 },
      { x: spearX - spearLength, y: spearY - spearWidth / 2 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#292D2D",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "Bconnect",
    type: "polygon",
    points: [
      { x: connectX, y: connectY },
      { x: connectX, y: connectY + handleSize },
      { x: connectX + connectLength, y: connectY + handleSize / 2 },
    ],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#414747",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  // Empty drawing pieces so object switch works.
  {
    name: "prong1",
    type: "polygon",
    points: [{ x: 0, y: 0 }],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#5F6464",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "tip1",
    type: "polygon",
    points: [{ x: 0, y: 0 }],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#A0A8A8",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "prong2",
    type: "polygon",
    points: [{ x: 0, y: 0 }],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#5F6464",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
  {
    name: "tip2",
    type: "polygon",
    points: [{ x: 0, y: 0 }],
    xTrans: 0,
    yTrans: 0,
    fillStyle: "#A0A8A8",
    lineWidth: 4,
    strokeStyle: "#000000",
    rotation: (Math.PI * 0) / 180,
  },
];

export default boarspear