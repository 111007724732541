import { useSelector, useDispatch } from "react-redux";

import MenuScreen from "./menu/MenuScreen";

import GameScreen from "./game/GameScreen";
import PAGES from "../constants/moba";
import { connect, onGameOver } from "../utils/networking";
import { useEffect } from "react";

export default function Moba() {
 
  // Moba.js will be a centralized management file 
  // Active Page (SPA). 
  const activePage = useSelector((state)=>state.moba.activePage);

  useEffect(() => {
    connect(onGameOver);
  }, []);

  return (
    <>
      {activePage == PAGES.MENU_SCREEN_USERNAME && <MenuScreen />}
      {activePage == PAGES.MENU_SCREEN_CHAMPIONS && <MenuScreen />}
      {activePage == PAGES.GAME_SCREEN && <GameScreen />}
    </>
  );
}
