import styles from "./ChampionBar.module.css";
import anduin from "../../../../assets/anduin.png"
import bard from "../../../../assets/bard.png";
import juggernaut from "../../../../assets/juggernaut.png";
import omen from "../../../../assets/omen.png";
import stranger from "../../../../assets/stranger.png";
import victorious from "../../../../assets/victorious.jpg";

import ChampionNames from "../../../../constants/ChampionNames";
import { useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux"
import { setHoveredChampion, setSelectedChampion } from "../../../../redux/actions/championSelect-action";
import allChampions from "../../../../game/champions/champions"




const championNames = [
  ChampionNames.REMI,
  ChampionNames.JIRQO,
  ChampionNames.LUCCA_BOAR,
  ChampionNames.FUTURE_CHAMPION_1,
  ChampionNames.FUTURE_CHAMPION_2,
  ChampionNames.FUTURE_CHAMPION_3,
];


//todo: what is this
const noImageAvatarURL = "https://www.shutterstock.com/image-vector/vector-illustration-missing-person-graphic-600nw-775954000.jpg"



function ChampionBar() {
    const dispatch = useDispatch();
    const selectedChampion = useSelector((state) => state.championSelect.selectedChampion);


    const createChampionElement = (name) => (
      <div
        className={`${styles.imageWrapper} 
        ${championIsSelected() ? null : styles.noSelectionImageWrapper}
        ${isSelectedChampion(name) ? styles.selectedImageWrapper : null}
        `}
        onClick={() => onChampionSelect(name)}
        onMouseOver={() => onChampionHover(name)}
      >
        <img
          className={styles.championImage}
          src={allChampions[name] ? allChampions[name].image : noImageAvatarURL}
        ></img>
        <div
          className={allChampions[name].locked ? styles.lockOverlay : null}
        ></div>
      </div>
    );

    function onChampionSelect (name) {
      dispatch(setSelectedChampion(name));
    }
    function onChampionHover (name) {
      dispatch(setHoveredChampion(name));
    }
    function championIsSelected() {
      return selectedChampion != ChampionNames.NO_CHAMPION;
    }
    function isSelectedChampion(name) {
      return selectedChampion == name;
    }

    return (
      <div className={styles.container}>
        {championNames.map((name) => createChampionElement(name))}
      </div>
    );
}

export default ChampionBar;
