import {
  AbilityConstants,
  DynamicInputs,
} from "../../../../constants/AbilitySystemConstants";
import qImage from "../../../../assets/sprites/q.png";
import eImage from "../../../../assets/sprites/e.png";
import rImage from "../../../../assets/sprites/r.png";

const luccaAbilities = {
    [AbilityConstants.ATTACK]: {
      cooldown: 1000,
    },
    [AbilityConstants.Q]: {
      cooldown: 1000,
      name: "Swap Armament",
      description: "Swap between a boar-spear and a spetum.",
      image: qImage,
    },
    // Only required for dynamic input.
    [AbilityConstants.E]: {
      cooldown: 7000,
      dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
      name: "Shield Bash",
      description: "Dash forward with a shield, knocking back and stunning.",
      image: eImage,
    },
    [AbilityConstants.R]: {
      cooldown: 50000,
      name: "Indomitable",
      description: "Grow in size, gain health, movement speed, and attack range.",
      image: rImage,
    },
  }


export default luccaAbilities;
