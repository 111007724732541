import { useEffect, useRef, useMemo} from "react";
import styles from "./Game.module.css";
import { useSelector } from "react-redux";
import handleInput from "../../game/controllers/handleInput";
import processGameLoop from "../../game/view/processGameLoop";
import { playerAnimationManager, projectileAnimationManager } from "../../game/model/AnimationManager";

export default function Game() {
  let gameState = useSelector((state) => state.game.gameState);
  let canvasRef = useRef(null);

  //CONTROLLERS
  //Handle movement controls.
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvasRef && canvasRef.current) {
      //Focus the canvas (Like clicking on a screen so it registers your input)
      canvas.focus();
      handleInput(canvas);
    }
  }, []);
  // Resize screen
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      window.addEventListener("resize", resizeCanvas, false);
      function resizeCanvas() {
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
      }
      resizeCanvas();
    }
  }, []);

  //VIEW
  //Begin render loop
  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvasRef && canvasRef.current) {
      requestAnimationFrame(()=>processGameLoop(canvas))
    }
  }, []);

  return (
      <canvas
        tabIndex="0"
        ref={canvasRef}
        width="500px"
        height="500px"
      ></canvas>
  );
}
