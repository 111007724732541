import { nanoid } from "nanoid";
import { ObjectStateConstants } from "../../constants/AnimationObjectConstants";
import TrailNames from "../../constants/TrailNames";



class TrailManager{
    constructor(){
        this.trails = [];
    }
    getTrails(){
        return this.trails;
    }
    addCircleTrail(pos){
        let circleTrail = {
          id: nanoid(),
          objectName: TrailNames.CIRCLE_TRAIL,
          pos: pos,
          state: ObjectStateConstants.ACTIVE,
          expireTime: Date.now()+300,
        };
        this.trails.push(circleTrail);
    }
    update(){
        let curTime = Date.now();
        this.trails = this.trails.filter((trail)=>curTime<trail.expireTime)
    }
}

const trailManager = new TrailManager();
export default trailManager;