const bowTurnRotation = 50
const hatTurnRotation = 100;
const turnDuration = 150;

const pullDelay = turnDuration

const eAnimation = {
  animation: [
    // Turn Right Animation
    {
      name: "recurveBow",
      duration: turnDuration,
      rotation: (bowTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "drawString",
      duration: turnDuration,
      rotation: (bowTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "arm1",
      duration: turnDuration,
      rotation: (bowTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "arm2",
      duration: turnDuration,
      rotation: (bowTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "hatBow",
      duration: turnDuration,
      rotation: (hatTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "hatShadow",
      duration: turnDuration,
      rotation: (hatTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "hatCenter",
      duration: turnDuration,
      rotation: (hatTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    {
      name: "hatFeather",
      duration: turnDuration,
      rotation: (hatTurnRotation * Math.PI) / 180,
      x: 0,
      y: 0,
      delay: 0,
    },
    //Pull Drawstring
    {
      name: "arm2",
      duration: turnDuration,
      rotation: (bowTurnRotation * Math.PI) / 180,
      x: 0,
      y: -30,
      delay: pullDelay,
    },
  ],
};

export default eAnimation;