import {
  AbilityConstants,
  DynamicInputs,
} from "../../../../constants/AbilitySystemConstants";
import qImage from "../../../../assets/sprites/locked.jpg";
import eImage from "../../../../assets/sprites/locked.jpg";
import rImage from "../../../../assets/sprites/locked.jpg";



const futureAbilities = {
  [AbilityConstants.ATTACK]: {
    cooldown: 2000,
  },
  [AbilityConstants.Q]: {
    cooldown: 2000,
    name: "Unknown",
    description: "???",
    image: qImage,
  },
  [AbilityConstants.E]: {
    cooldown: 2000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Unknown",
    description: "???",
    image: eImage,
  },
  [AbilityConstants.R]: {
    cooldown: 2000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Unknown",
    description: "???",
    image: rImage,
  },
};
export default futureAbilities;
