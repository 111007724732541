export const AbilityConstants = {
  ATTACK: "ATTACK",
  Q: "Q",
  E: "E",
  R: "R",
};

export const DynamicInputs = {
  MOUSE_DIRECTION: "mouseDir",
};
