import styles from "./Menu.module.css";
import { useEffect, useRef } from "react";
import PAGES from "../../constants/moba";
import { useSelector } from "react-redux";
import MenuUI from "./MenuUI/MenuUI";
import AllTimeLeaderboard from "../AllTimeLeaderboard/AllTimeLeaderboard";
import SocialsDisplay from "./SocialsDisplay/SocialsDisplay";
import Copyright from "./Copyright/Copyright";
import ChampionSelect from "./ChampionSelect/ChampionSelect";
import MenuTitle from "./MenuTitle/MenuTitle";

export default function Menu() {
  let menuRef = useRef(null);
  const activePage = useSelector((state) => state.moba.activePage);

  function parallax(e) {
    if (menuRef && menuRef.current){
      let _w = window.innerWidth / 2;
      let _h = window.innerHeight / 2;
      let _mouseX = e.clientX;
      let _mouseY = e.clientY;
      let _depth1 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.005
      }%`;
      let _depth2 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.01
      }%`;
      let _depth3 = `${50 + (_mouseX - _w) * 0.01}% ${
        50 + (_mouseY - _h) * 0.03
      }%`;
      let x = `${_depth3}, ${_depth2}, ${_depth1}`;
      menuRef.current.style.backgroundPosition = x;
    }
  }

  useEffect(()=>{
    if (menuRef && menuRef.current){
      document.addEventListener("mousemove", parallax)
    }
  },[])

  return (
    <div ref={menuRef} className={styles.container}>
      <div className={styles.overlay}></div>
      <MenuTitle />
      <AllTimeLeaderboard />
      {activePage == PAGES.MENU_SCREEN_USERNAME && <MenuUI />}
      {activePage == PAGES.MENU_SCREEN_CHAMPIONS && <ChampionSelect />}
      <SocialsDisplay />
      <Copyright />
    </div>
  );
}
