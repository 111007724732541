import {
  AbilityConstants,
  DynamicInputs,
} from "../../../../constants/AbilitySystemConstants";
import qImage from "../../../../assets/sprites/q.png";
import eImage from "../../../../assets/sprites/e.png";
import rImage from "../../../../assets/sprites/r.png";


const mykolaAbilities = {
  [AbilityConstants.ATTACK]: {
    cooldown: 1000,
  },
  [AbilityConstants.Q]: {
    cooldown: 5000,
    name: "Preparation For War",
    description: "Slash in the target direction with an empowered attack.",
    image: qImage,
  },
  // Only required for dynamic input.
  [AbilityConstants.E]: {
    cooldown: 10000,
    dynamicInputs: [DynamicInputs.MOUSE_DIRECTION],
    name: "Ceremonial Leap",
    description: "Dash in the target direction.",
    image: eImage,
  },
  [AbilityConstants.R]: {
    cooldown: 50000,
    name: "Tradition's Honor",
    description:
      "Spin with dual scimitars, dealing high damage over time around the player champion.",
    image: rImage,
  },
};

export default mykolaAbilities;
