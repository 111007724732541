import bombArrowDrawInfo from "./bombArrowDrawInfo";
import bombArrowAnimation from "./bombArrowAnimation";
import { ObjectStateConstants } from "../../../../constants/AnimationObjectConstants";

const bombArrowInfo = {
  drawing: bombArrowDrawInfo,
  animations: {
    [ObjectStateConstants.ACTIVE] : bombArrowAnimation
  },
  radius: 10,
};

export default bombArrowInfo;
