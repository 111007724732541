import styles from "./Map.module.css";

export default function Map({xPos, yPos}) {
  
    let mapWidth = 10500, mapHeight = 8400

    let leftPercent = `${xPos / mapWidth * 100}%`;
    let topPercent = `${yPos / mapHeight * 100}%`;


  return (
    <div className={styles.container}>
      <div style={{left: leftPercent, top: topPercent}}className={styles.playerPos}></div>
    </div>
  );
}
