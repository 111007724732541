import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants";

let stunColor = "#FEC601";

export const statusDrawInfo = {
  [ObjectStateConstants.STUNNED]: {
    drawing: [
      {
        name: "spiral1",
        type: "circle",
        radius: 20,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 0,
        y: 0,
        rotation: 0,
      },
      {
        name: "spiral1",
        type: "circle",
        radius: 16,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 4,
        y: 0,
        rotation: (180 * Math.PI) / 180,
      },
      {
        name: "spiral2",
        type: "circle",
        radius: 12,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 0,
        y: 0,
        rotation: (0 * Math.PI) / 180,
      },
      {
        name: "spiral3",
        type: "circle",
        radius: 8,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 4,
        y: 0,
        rotation: (180 * Math.PI) / 180,
      },
      {
        name: "spiral4",
        type: "circle",
        radius: 4,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 0,
        y: 0,
        rotation: (0 * Math.PI) / 180,
      },
      {
        name: "spiral5",
        type: "circle",
        radius: 1,
        fillStyle: "none",
        lineWidth: 4,
        strokeStyle: stunColor,
        arc: (180 * Math.PI) / 180,
        x: 3,
        y: 0,
        rotation: (180 * Math.PI) / 180,
      },
    ],
  },
};

