import { UPDATE_SELECTED_CHAMPION, UPDATE_HOVERED_CHAMPION, UPDATE_SELECTED_ABILITY } from "../constants/championSelect-types";
import ChampionNames from "../../constants/ChampionNames";
import { AbilityConstants } from "../../constants/AbilitySystemConstants";

const initialState = {
  selectedChampion: ChampionNames.NO_CHAMPION,
  hoveredChampion: ChampionNames.NO_CHAMPION,
  selectedAbility: AbilityConstants.Q,
};

export default function championSelectReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SELECTED_CHAMPION:
      return {
        ...state,
        selectedChampion: action.champion,
      };
    case UPDATE_HOVERED_CHAMPION:
      return {
        ...state,
        hoveredChampion: action.champion,
      };
    case UPDATE_SELECTED_ABILITY:
      return {
        ...state,
        selectedAbility: action.ability,
      };
    default:
      return state;
  }
}
