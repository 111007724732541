import strangerImage from "../../../assets/strangerSmudged.png"
import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion2 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: strangerImage,
  name: "????????",
  biography: `
Pou mwen cheri ▒▒▒, Zansèt nou yo leve nan rebelyon, ranvèse opresè yo epi reprann lavi yo te vòlè a, men se pa lavi ou poko viv la. Mwen te ale ............ ofri lavi l, ak nan je manman l, mwen wè yon refleksyon nan ou. Oh, ▒▒▒, ki jan mwen ta ka repete menm zak ki te wete w nan men mwen an?`,
  locked: true,
};

export default futureChampion2;
