import "./App.css";
import store from "./redux/store";
import {Provider } from "react-redux";
import Moba from "./pages/Moba";
import { useEffect } from "react";
// APP.JS DOCUMENTATION
// Redux Provider setup.
// Networking setup.

function App() {
  return (
    <Provider store={store}>
      <Moba />
    </Provider>
  );
}

export default App;
