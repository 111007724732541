import omenImage from "../../../assets/omenBlurred.png"

import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion1 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: omenImage,
  name: "ư̴̡̡̨̪̱͚̞̜͎̲̱̓̑̎̓̾͘̚ǝ̷͈̬̙͍͕̔͑̂̑͝ɯ̵̼̦̬̹̣̝̻̉͜ǭ̷͇̜͚̙̝̱̱̝̗͔",
  biography: `
  ˙ʇuǝƃᴉllǝʇuᴉ ɯǝuoᴉʇnqᴉɹʇǝɹ ʇǝ 'ᴉƃuᴉɹʇsɐ snqᴉxǝu ǝɐns sᴉʇɐʇᴉlɐʇɹoɯ 'ǝɹɐɔoʌuᴉ ʇuǝpnɐ ɯɐnƃuᴉl sᴉʇuǝdɹǝs ɯǝlᴉʌ ᴉnQ :sᴉuoᴉʇᴉuƃoɔ ǝɐʇᴉʇǝʌ sǝʇǝɹdɹǝʇuᴉ ǝʇǝʌɐƆ
  `,
  locked: true,
  //TODO: Frontend lock needs to be backend checked during deployment.
};

export default futureChampion1;
