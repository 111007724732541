import victoriousImage from "../../../assets/victorious.jpg"
import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import futureAbilities from "./abilities/futureAbilities";

export const futureChampion3 = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: victoriousImage,
  name: ".....",
  biography: `.... --- .-- ....... -.. .. -.. ....... -.-- --- 
  ..- ....... . ...- . -. ....... - .-. .- -. ... .-.. 
  .- - . ....... - .... .. ... ..--..`,
  locked: true,
};

export default futureChampion3;
