const deathAnimation = {
  applyToAllParts: true,
  animation: [
    {
      name: "body",
      duration: 400,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      fillStyle: "#FFFFFF00",
      delay: 600,
    },
    {
      name: "body",
      duration: 400,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1.5,
      delay: 600,
    },
    {
      name: "body",
      duration: 400,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      strokeStyle: "#00000000",
      delay: 600,
    },
  ],
};

export default deathAnimation