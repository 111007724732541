import { SET_ACTIVE_PAGE } from "../constants/moba-types";
import PAGES from "../../constants/moba";

const initialState = {
  activePage: PAGES.MENU_SCREEN_USERNAME,
};

export default function mobaReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload,
      };
    default:
      return state;
  }
}
