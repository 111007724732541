
import bowDrawInfo from "./bow";
import hatDrawInfo from "./hat";

let bodyColor = "#f5e1ce";


const jirqoDrawInfo = {
  shapes: [
    ...bowDrawInfo,
    {
      name: "arm1",
      type: "circle",
      radius: 9,
      fillStyle: bodyColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 50,
      y: 20,
      rotation: (Math.PI * 60) / 180,
    },
    {
      name: "arm2",
      type: "circle",
      radius: 9,
      fillStyle: bodyColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 28,
      y: 12,
      rotation: (Math.PI * -60) / 180,
    },
    {
      name: "body",
      type: "circle",
      radius: 25,
      fillStyle: bodyColor,
      lineWidth: 6,
      strokeStyle: "#000000",
      x: 0,
      y: 0,
      rotation: 0,
    },
    ...hatDrawInfo,
  ],
};
export default jirqoDrawInfo;
