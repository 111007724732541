import { io } from "socket.io-client";
import { updateGameState } from "../redux/actions/gameState-action";
import { setActivePage } from "../redux/actions/moba-action";
import PAGES from "../constants/moba";
import store from "../redux/store";
import { prepareGameState } from "./gameStatePrimingFuncs";

const socket = io("http://167.172.120.125:80");

const connectedPromise = new Promise((resolve) => {
  socket.on("connect", () => {
    resolve()
  });
});


export const connect = (onGameOver) =>
  connectedPromise.then(() => {
    // Register callbacks
    // Your Code Here
    //When the user switches tabs this might activate. You 
    //Might want to add some functionality to "reconnect".
    socket.on("disconnect", () => {
      console.log("Disconnected"); 
      store.dispatch(setActivePage(PAGES.MENU_SCREEN_USERNAME))
    });

    socket.on("game-update", (gameState) => {
      prepareGameState(gameState)
      store.dispatch(updateGameState(gameState))
    })

    socket.on("game-over", () => {
     onGameOver();
    });
  });

export const updateServerDirectionInputs = (dir) => {
  socket.emit("update-direction-inputs", dir);
};
export const updateServerRotation = (rotation) => {
  socket.emit("update-rotation", rotation);
};

export const sendAbilityRequest = (ability, data) => {
  //Data should be an object with any extra info needed
  let payload = {
    "ability" : ability,
    "data" : data,
  }
  socket.emit("send-ability-request", payload);
};

export const joinGame = (playerName, champion) => {
  socket.emit("join-game", {playerName, champion})
}

export function onGameOver(){
  setTimeout(
    () => store.dispatch(setActivePage(PAGES.MENU_SCREEN_USERNAME)),
    2000
  );
}
