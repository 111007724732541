import styles from "./SocialsDisplay.module.css"
import { FaReddit } from "react-icons/fa";
import { FaDiscord } from "react-icons/fa";
import { FaPatreon } from "react-icons/fa";
import { IconContext } from "react-icons";

export default function SocialsDisplay(){
    const iconSize = "2.8em";

    return (
      <div className={styles.container}>
        <div className={styles.socialsFlexbox}>
          <IconContext.Provider value={{ size: iconSize, style: {width: "44px", height: "44px"}}}>
              <FaReddit />
          </IconContext.Provider>
          <IconContext.Provider value={{ size: iconSize, style: {width: "44px", height: "44px"} }}>
            <FaDiscord />
          </IconContext.Provider>
          <IconContext.Provider value={{ size: iconSize, style: {width: "44px", height: "44px"} }}>
            <FaPatreon />
          </IconContext.Provider>
        </div>
      </div>
    );
}