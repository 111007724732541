import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants.js";
import { AbilityConstants, DynamicInputs } from "../../../constants/AbilitySystemConstants.js";

import spetumAttackAnimation from "./animations/spetumAttackAnimation.js";
import eAnimation from "./animations/eAnimation.js"
import ultimateAnimation from "./animations/ultimateAnimation.js";
import deathAnimation from "../damageAnimations/deathAnimation.js";
import drawLuccaSpetumInfo from "./drawings/drawLuccaSpetum.js";
import luccaAbilities from "./abilities/luccaAbilities.js";

const luccaSpetumInfo = {
  drawing: drawLuccaSpetumInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: spetumAttackAnimation,
    [ObjectStateConstants.CASTING_Q]: { animation: [] },
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: ultimateAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH]: deathAnimation,
  },
  abilities: luccaAbilities,
};

export default luccaSpetumInfo;
