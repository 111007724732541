//Drawing Constants
let swordIn = -8;

let bodyColor = "#EBF2FA";
let bladeColor = "#EBF2FA";
let goldColor = "#FCDF54";
let goldColor2 = "#FFC700";


const drawInfo = {
  shapes: [
    {
      name: "blade1",
      type: "ellipse",
      x: 27,
      y: -32,
      radiusX: 6,
      radiusY: 45,
      fillStyle: bladeColor,
      lineWidth: 4,
      strokeStyle: "#000000",
      rotation: (Math.PI * (90 + swordIn)) / 180,
      selfRotation: (Math.PI * 0) / 180,
      startAngle: (Math.PI * 270) / 180,
      endAngle: (Math.PI * 90) / 180,
    },

    {
      name: "blade1inside",
      type: "line",
      x1: 19,
      y1: 27,
      x2: 78,
      y2: 27,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * swordIn) / 180,
    },
    {
      name: "handle1",
      type: "rectangle",
      x: -13,
      y: 28,
      width: 30,
      height: 5,
      fillStyle: "#3A3939",
      lineWidth: 5,
      strokeStyle: "#000000",
      rotation: (Math.PI * swordIn) / 180,
    },
    {
      name: "guard1",
      type: "rectangle",
      x: 13.5,
      y: 19.5,
      width: 3,
      height: 20,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      rotation: (Math.PI * swordIn) / 180,
    },
    {
      name: "ball1",
      type: "circle",
      radius: 3,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 15,
      y: 40,
      rotation: (Math.PI * swordIn) / 180,
    },

    {
      name: "ball2",
      type: "circle",
      radius: 3,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 15,
      y: 20,
      rotation: (Math.PI * swordIn) / 180,
    },
    // SECOND BLADE
    {
      name: "blade2",
      type: "ellipse",
      x: -27,
      y: -32,
      radiusX: 6,
      radiusY: 45,
      fillStyle: bladeColor,
      lineWidth: 4,
      strokeStyle: "#000000",
      rotation: (Math.PI * (90 - swordIn)) / 180,
      selfRotation: (Math.PI * 0) / 180,
      startAngle: (Math.PI * 90) / 180,
      endAngle: (Math.PI * 270) / 180,
    },

    {
      name: "blade2inside",
      type: "line",
      x1: 19,
      y1: -27,
      x2: 78,
      y2: -27,
      lineWidth: 2,
      strokeStyle: "#000000",
      rotation: (Math.PI * -swordIn) / 180,
    },
    {
      name: "handle2",
      type: "rectangle",
      x: -13,
      y: -33,
      width: 30,
      height: 5,
      fillStyle: "#3A3939",
      lineWidth: 5,
      strokeStyle: "#000000",
      rotation: (Math.PI * -swordIn) / 180,
    },
    {
      name: "guard2",
      type: "rectangle",
      x: 13.5,
      y: -39.5,
      width: 3,
      height: 20,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      rotation: (Math.PI * -swordIn) / 180,
    },
    {
      name: "ball3",
      type: "circle",
      radius: 3,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 15,
      y: -40,
      rotation: (Math.PI * -swordIn) / 180,
    },

    {
      name: "ball4",
      type: "circle",
      radius: 3,
      fillStyle: goldColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 15,
      y: -20,
      rotation: (Math.PI * -swordIn) / 180,
    },
    // ARMS
    {
      name: "arm1",
      type: "circle",
      radius: 9,
      fillStyle: bodyColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 28,
      y: 0,
      rotation: (Math.PI * 80) / 180,
    },
    {
      name: "arm2",
      type: "circle",
      radius: 9,
      fillStyle: bodyColor,
      lineWidth: 5,
      strokeStyle: "#000000",
      x: 28,
      y: 0,
      rotation: (Math.PI * -80) / 180,
    },
    {
      name: "body",
      type: "circle",
      radius: 25,
      fillStyle: bodyColor,
      lineWidth: 6,
      strokeStyle: "#000000",
      x: 0,
      y: 0,
      rotation: 0,
    },
  ],
};

export default drawInfo;