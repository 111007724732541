import { useMemo, useRef } from "react";
import styles from "./AllTimeLeaderboard.module.css"
import {useSelector} from "react-redux"
import dummyData from "./dummyData";



export default function AllTimeLeaderboard(){

    const createEntry = (player, i) => {
      return (
        <div className={styles.LBEntry}>
          <div className={styles.entry1}>{i+1}</div>
          <div className={styles.entry2}
          >{player.name}</div>
          <div>{player.wins}</div>
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div className={styles.LBTitle}>All Time Leaderboard</div>
        <div className={styles.LBHeaders}>
          <div className={styles.entry1}>Rank</div>
          <div className={styles.entry2}>Name</div>
          <div>Wins</div>
        </div>
        {dummyData.topRankingPlayersQuery
          ? dummyData.topRankingPlayersQuery.map((player, i) =>
              createEntry(player, i)
            )
          : null}
      </div>
    );
}