import ProjectileNames from "../../constants/ProjectileNames";

import arrowInfo from "./arrows/arrow/arrow";
import poisonArrowInfo from "./arrows/poisonArrow/poisonArrow";
import glueArrowInfo from "./arrows/glueArrow/glueArrow";
import bombArrowInfo from "./arrows/bombArrow/bombArrow";

const allProjectiles = {
  [ProjectileNames.ARROW]: arrowInfo,
  [ProjectileNames.POISON_ARROW]: poisonArrowInfo,
  [ProjectileNames.GLUE_ARROW] : glueArrowInfo,
  [ProjectileNames.BOMB_ARROW] : bombArrowInfo,
}; 

export default allProjectiles;