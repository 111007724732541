const dummyData = {
  topRankingPlayersQuery: [
    { name: "pixelhazer ", wins: 43 },
    { name: "Glitch3d  ", wins: 41 },
    { name: "phantomBites", wins: 37 },
    { name: "zen0pathy  ", wins: 31 },
    { name: "QuantumFox  ", wins: 30 },
    { name: "MemeMachine  ", wins: 20 },
    { name: "asdf  ", wins: 15 },
    { name: "Guest6009 ", wins: 14 },
    { name: "tubby ", wins: 12 },
    { name: "magicmaster0303 ", wins: 11 },
  ],
};

export default dummyData;