import { ObjectStateConstants } from "../constants/AnimationObjectConstants";

export function assignStatesToProjectiles(gameState){
  for (let projectile of gameState.projectiles) {
    projectile.state = ObjectStateConstants.ACTIVE;
  }
}

export function assignNamesToPlayers(gameState){
    for (let player of gameState.players) {
      player.objectName = player.champion
    }
}

export function prepareGameState(gameState){
    assignStatesToProjectiles(gameState)
    assignNamesToPlayers(gameState)
}