import { UPDATE_USER_DIRECTION, UPDATE_MOUSE_DIRECTION, DIRECTIONS } from "../constants/userInput-types";

const initialState = {
  dir: { [DIRECTIONS.LEFT]: false, [DIRECTIONS.RIGHT]: false, [DIRECTIONS.UP]: false, 
  [DIRECTIONS.DOWN]: false, 
  mouseDir : 0,
  },
};

export default function userInputReducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_DIRECTION:
      // Movement is based off of 4 booleans that are toggled on update.
      return {
        ...state,
        dir: { ...state.dir, ...action.dir },
      };
    case UPDATE_MOUSE_DIRECTION:
      // Direction is based off of mouse's direction relative to player.
      return {
        ...state,
        mouseDir: action.mouseDir,
      };
    default:
      return state;
  }
}
