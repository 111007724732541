//ANIMATION CONSTANTS
//////////////////////

const growDuration = 200;
const growScale = 1.5;

const shrinkDuration = growDuration;
const shrinkDelay = 13500;

const ultimateAnimation = {
  animation: [
    /////////////
    //BODY GROW//
    /////////////
    {
      name: "body",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "helmetmouth",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "helmetvisor",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "lobe",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "helmettop",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "line1",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "line2",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    ///////////////
    //WEAPON GROW//
    ///////////////
    {
      name: "Bhandle",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "Bguard",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "Bspear",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "Bconnect",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "prong1",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "prong2",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "tip1",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    {
      name: "tip2",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    ////SHIELD
    {
      name: "shield",
      duration: growDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: growScale,
      delay: 0,
    },
    ///////////////
    //BODY SHRINK//
    ///////////////
    {
      name: "body",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "helmetmouth",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "helmetvisor",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "lobe",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "helmettop",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "line1",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "line2",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    /////////////////
    //WEAPON SHRINK//
    /////////////////
    {
      name: "Bhandle",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "Bguard",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "Bspear",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "Bconnect",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "prong1",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "prong2",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "tip1",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    {
      name: "tip2",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
    ////SHIELD
    {
      name: "shield",
      duration: shrinkDuration,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 1,
      delay: shrinkDelay,
    },
  ],
};

export default ultimateAnimation;
