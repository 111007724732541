import styles from "./Cooldown.module.css";
import { useSelector } from "react-redux";
import allChampions from "../../../../game/champions/champions.js"

function clamp(value, min, max) {
  return Math.max(min, Math.min(max, value));
}

export default function Cooldown({ability}) {
  let cooldowns = useSelector((state)=>state.userData.cooldowns)
  let champion = useSelector((state)=>state.game.gameState.me.champion)
  let abilityCooldown = allChampions[champion].abilities[ability].cooldown;
  let curTime = Date.now() 


  let segment1 = clamp((cooldowns[ability] - curTime)  - (3*abilityCooldown/4), 0, abilityCooldown/4)
  let segment2 = clamp((cooldowns[ability] - curTime)  - (2*abilityCooldown/4), 0, abilityCooldown/4)
  let segment3 = clamp((cooldowns[ability] - curTime)  - (1*abilityCooldown/4), 0, abilityCooldown/4)
  let segment4 = clamp((cooldowns[ability] - curTime)  - (0*abilityCooldown/4), 0, abilityCooldown/4)

  let degree1 = Math.round(segment1 / (abilityCooldown / 4) * 90);
  let degree2 = Math.ceil(segment2 / (abilityCooldown / 4) * 90); 
  let degree3 = Math.ceil(segment3 / (abilityCooldown / 4) * 90); 
  let degree4 = Math.ceil(segment4 / (abilityCooldown / 4) * 90); 

  let number = Math.ceil((cooldowns[ability] - curTime)/1000);

  return (
    <div className={styles.container}>
      <div className={styles.cooldownCircle}>
        <div
          className={styles.cutout}
          style={{ transform: `rotate(270deg) skew(-${90 - degree1}deg)` }}
        ></div>
        <div
          className={styles.cutout}
          style={{ transform: `rotate(0deg) skew(-${90 - degree2}deg)` }}
        ></div>
        <div
          className={styles.cutout}
          style={{ transform: `rotate(90deg) skew(-${90 - degree3}deg)` }}
        ></div>
        <div
          className={styles.cutout}
          style={{ transform: `rotate(180deg) skew(-${90 - degree4}deg)` }}
        ></div>
      </div>
      <div className={styles.number}>{number}</div>
    </div>
  );
}
