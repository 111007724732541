import glueArrowDrawInfo from "./glueArrowDrawInfo";
import glueArrowAnimation from "./glueArrowAnimation";
import { ObjectStateConstants } from "../../../../constants/AnimationObjectConstants";

const glueArrowInfo = {
  drawing: glueArrowDrawInfo,
  animations: {
    [ObjectStateConstants.ACTIVE] : glueArrowAnimation
  },
  radius: 160,
};

export default glueArrowInfo;
