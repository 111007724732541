const circleTrailAnimation = {
  animation: [
    // Wind Up Animation
    {
      name: "circle_base",
      duration: 300,
      delay: 0,
      fillStyle: "#00000000",
      strokeStyle: "#00000000",
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
    },
  ],
};

export default circleTrailAnimation;
