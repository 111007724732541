const BiomeConstants = {
  PLAINS: 0,
  FOREST: 1,
  SNOW: 2,
  MOUNTAIN: 3,
  OCEAN: 4,
  RIVER: 5,
  BEACH: 6,
  DESERT: 7 ,
};

export default BiomeConstants;
