
import { UPDATE_SELECTED_CHAMPION, UPDATE_HOVERED_CHAMPION, UPDATE_SELECTED_ABILITY } from "../constants/championSelect-types";

export function setSelectedChampion(champion) {
  return {
    type: UPDATE_SELECTED_CHAMPION,
    champion: champion,
  };
}

export function setHoveredChampion(champion) {
  return {
    type: UPDATE_HOVERED_CHAMPION,
    champion: champion,
  };
}

export function setSelectedAbility(ability){
  return {
    type: UPDATE_SELECTED_ABILITY,
    ability: ability,
  }
}
