import { DIRECTIONS } from "../../redux/constants/userInput-types";
import { updateUserDirection, updateMouseDirection } from "../../redux/actions/userInput-action";
import store from "../../redux/store";
import { updateServerDirectionInputs, updateServerRotation, sendAbilityRequest } from "../../utils/networking";
import { AbilityConstants } from "../../constants/AbilitySystemConstants";
import allChampions from "../champions/champions.js"
import { updateCooldown } from "../../redux/actions/userData-actions.js";

export default function handleInput(domElement) {
  domElement.addEventListener("keydown", function (event) {
    switch (event.key) {
      case "a":
        store.dispatch(updateUserDirection({ [DIRECTIONS.LEFT]: true }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "d":
        store.dispatch(updateUserDirection({ [DIRECTIONS.RIGHT]: true }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "w":
        store.dispatch(updateUserDirection({ [DIRECTIONS.UP]: true }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "s":
        store.dispatch(updateUserDirection({ [DIRECTIONS.DOWN]: true }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      default:
        break;
    }
  });

  domElement.addEventListener("keyup", function (event) {
    switch (event.key) {
      case "a":
        store.dispatch(updateUserDirection({ [DIRECTIONS.LEFT]: false }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "d":
        store.dispatch(updateUserDirection({ [DIRECTIONS.RIGHT]: false }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "w":
        store.dispatch(updateUserDirection({ [DIRECTIONS.UP]: false }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      case "s":
        store.dispatch(updateUserDirection({ [DIRECTIONS.DOWN]: false }));
        updateServerDirectionInputs(store.getState().userInput.dir);
        break;
      default:
    }
  });

  //Record mouse direction.
  domElement.addEventListener("mousemove", function (event) {
    const x2 = event.clientX;
    const y2 = event.clientY;

    //Mouse position - center of the screen
    const dx = x2 - domElement.width/2 
    const dy = y2 - domElement.height/2 

    const angleRadians = Math.atan2(dy, dx);
    store.dispatch(updateMouseDirection(angleRadians));
    updateServerRotation(store.getState().userInput.mouseDir);
  });

  //Auto Attack.
  domElement.addEventListener("click", function (event) {
    let context = {};
    let champion = store.getState().game.gameState.me.champion;
    //Record potential additional arguments
     let dynamicInputs =
       allChampions[champion].abilities[AbilityConstants.E].dynamicInputs;
    if (dynamicInputs){
      for (let input of dynamicInputs) {
        context[input] = store.getState().userInput[input];
      }
    }
    //Send attack request to server.
    sendAbilityRequest(AbilityConstants.ATTACK, context);
    store.dispatch(updateCooldown(AbilityConstants.ATTACK, champion));
    // Animation Manager handles animation.

  });
  //Ability CASTING
  domElement.addEventListener("keydown", function (event) {
    let context = {}
    let champion = store.getState().game.gameState.me.champion;
    switch (event.key) {
      case "q":
        sendAbilityRequest(AbilityConstants.Q, context);
        store.dispatch(updateCooldown(AbilityConstants.Q, champion));
        break;
      case "e":
        let dynamicInputs =
          allChampions[champion].abilities[AbilityConstants.E].dynamicInputs;
        for (let input of dynamicInputs){
          context[input] = store.getState().userInput[input]
        }
        sendAbilityRequest(AbilityConstants.E, context);
        store.dispatch(updateCooldown(AbilityConstants.E, champion));
        break;
      case "r":
        sendAbilityRequest(AbilityConstants.R, context);
        store.dispatch(updateCooldown(AbilityConstants.R, champion));
        break;
      default:
        break;
    }
  })
}
