import styles from "./ChampionSelect.module.css"
import ChampionBar from "./ChampionBar/ChampionBar"
import SelectButton from "./SelectButton/SelectButton"
import ChampionDescription from "./ChampionDescription/ChampionDescription";

function ChampionSelect() {
    return (
      <div className={styles.container}>
        <ChampionBar />
        <SelectButton />
        <ChampionDescription />
      </div>
    );
}

export default ChampionSelect