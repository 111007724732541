import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants.js";
import {
  AbilityConstants,
  DynamicInputs,
} from "../../../constants/AbilitySystemConstants.js";

import boarAttackAnimation from "./animations/boarAttackAnimation.js";
import eAnimation from "./animations/eAnimation.js";
import ultimateAnimation from "./animations/ultimateAnimation.js";
import deathAnimation from "../damageAnimations/deathAnimation.js";
import drawLuccaBoarInfo from "./drawings/drawLuccaBoar.js";
import luccaImage from "../../../assets/juggernaut.png";
import luccaAbilities from "./abilities/luccaAbilities.js";

const luccaBoarInfo = {
  drawing: drawLuccaBoarInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: boarAttackAnimation,
    [ObjectStateConstants.CASTING_Q]: { animation: [] },
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: ultimateAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH] : deathAnimation,
  },
  abilities: luccaAbilities,
  image: luccaImage,
  name: "Lucca",
  biography:
    `In a kingdom where heroes vie for glory, Lucca emerged from obscurity, 
    a man with neither title nor lineage. 
    Yet, through unmatched skill and unwavering resolve, 
    he claimed the champion's crown and the heart of a fair maiden. 
    His name, once unknown, now resonates through the halls of legend.`,
};

export default luccaBoarInfo;
