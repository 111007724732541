import arrowDrawInfo from "./arrowDrawInfo";
import arrowAnimation from "./arrowAnimation";
import { ObjectStateConstants } from "../../../../constants/AnimationObjectConstants";

const arrowInfo = {
  drawing: arrowDrawInfo,
  animations: {
    [ObjectStateConstants.ACTIVE] : arrowAnimation
  },
  radius: 10,
};

export default arrowInfo;
