import biomeGrid from "./mapInfo";

class MapManager {
    constructor(){
        this.grid = biomeGrid;
    }
    getBiome(xcoord, ycoord){
        return this.grid[ycoord][xcoord]
    }

    inBounds(xcoord, ycoord){
        return (xcoord >= 0 && ycoord >= 0 && xcoord < this.grid[0].length && ycoord < this.grid.length)
    }

    //Return the closest internal Biome given an out of bounds coordinate.
    closestInBoundsBiome(xcoord, ycoord){
        while (xcoord < 0){
            xcoord += 1;
        }
        while (xcoord >= this.grid[0].length) {
            xcoord -= 1;
        }
        while (ycoord < 0) {
          ycoord += 1;
        }
        while (ycoord >= this.grid.length) {
          ycoord -= 1;
        }
        return this.grid[ycoord][xcoord]
    }
}

const mapManager = new MapManager();

export default mapManager;