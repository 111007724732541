import styles from "./StatusBar.module.css";

export default function StatusBar({val, maxVal, isMana}) {

    const meterPercent = `${val/maxVal*100}%`

  return (
    <div
      style={isMana ? { bottom: "18px" } : null}
      className={styles.container}
    >
      <div
        style={isMana ? { background: "#339CC9", width: meterPercent } : {width: meterPercent}}
        className={styles.gloss}
      ></div>
      <div className={styles.label}>{isMana ? "MP" : "HP"}</div>
      <div className={styles.meter}>{val}/{maxVal}</div>
    </div>
  );
}
