import lockedImage from "../../../assets/sprites/locked.jpg"
import { AbilityConstants } from "../../../constants/AbilitySystemConstants";
import futureAbilities from "./abilities/futureAbilities";

export const noChampion = {
  drawing: {},
  animations: {},
  abilities: futureAbilities,
  image: lockedImage,
  name: "No Champion Selected",
  biography: ``,
};

export default noChampion;
