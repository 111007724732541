import { ObjectStateConstants } from "../../../constants/AnimationObjectConstants.js";
import { AbilityConstants, DynamicInputs } from "../../../constants/AbilitySystemConstants.js";

import remiImage from "../../../assets/anduin.png"
import drawInfo from "./drawInfo.js";
import attackAnimation from "./animations/attackAnimation.js";
import qAnimation from "./animations/qAnimation.js";
import eAnimation from "./animations/eAnimation.js";
import ultimateAnimation from "./animations/ultimateAnimation.js";
import deathAnimation from "../damageAnimations/deathAnimation.js";
import mykolaAbilities from "./abilities/mykolaAbilities.js";



const remiInfo = {
  drawing: drawInfo,
  animations: {
    [ObjectStateConstants.ATTACKING]: attackAnimation,
    [ObjectStateConstants.CASTING_Q]: qAnimation,
    [ObjectStateConstants.CASTING_E]: eAnimation,
    [ObjectStateConstants.CASTING_R]: ultimateAnimation,
    [ObjectStateConstants.STUNNED]: { animation: [] },
    [ObjectStateConstants.DEATH]: deathAnimation,
  },
  abilities: mykolaAbilities,
  image: remiImage,
  name: "Mykola",
  biography: `In a land torn by ceaseless raids, 
  Mykola's people forged their war arts in the fires of survival. 
  Their curved blades became extensions of their will, 
  and their children learned the sword's dance as soon as they could stand. 
  Mykola has mastered these dances, his movements a blur of steel and death.
    `,
};

export default remiInfo;
