import drawShapeWithAnimators from "./drawShapeWithAnimators";
import allProjectiles from "../projectiles/projectiles";
import renderHitbox from "./renderHitbox";
import { projectileAnimationManager } from "../model/AnimationManager";
import trailManager from "../model/TrailManager";

function alignCanvasWithPlayer(ctx, proj) {
  ctx.translate(proj.pos.x, proj.pos.y);
  ctx.rotate(proj.rotation + (90 * Math.PI) / 180);
}

function unalignCanvasFromPlayer(ctx, proj) {
  ctx.rotate(-proj.rotation - (90 * Math.PI) / 180);
  ctx.translate(-proj.pos.x, -proj.pos.y);
}

function renderProjectile(ctx, serverProjData){
    let projectileAnimator = projectileAnimationManager.getAnimator(serverProjData.id)
    alignCanvasWithPlayer(ctx, serverProjData)
    const clientProjData = allProjectiles[serverProjData.objectName];
    //todo: should be some sort of user/admin setting for this
    // renderHitbox(ctx, clientProjData);
    for (let shape of clientProjData.drawing.shapes){
      drawShapeWithAnimators(ctx, shape, projectileAnimator);
    }
    unalignCanvasFromPlayer(ctx, serverProjData)
}

export default renderProjectile