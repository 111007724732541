import fylgjaDrawInfo from "./fylgja/fylgja"
import remiInfo  from "./remi/remi";
import luccaSpetumInfo from "./lucca/luccaSpetum";
import luccaBoarInfo from "./lucca/luccaBoar";
import jirqoInfo from "./jirqo/jirqo";
import futureChampion1 from "./futureChampions/futureChampion1";
import futureChampion2 from "./futureChampions/futureChampion2";
import futureChampion3 from "./futureChampions/futureChampion3";
import noChampion from "./noChampion/noChampion";

import ChampionNames from "../../constants/ChampionNames";

const allChampions = {
  [ChampionNames.FYLGJA]: fylgjaDrawInfo,
  [ChampionNames.REMI]: remiInfo,
  [ChampionNames.LUCCA_SPETUM]: luccaSpetumInfo,
  [ChampionNames.LUCCA_BOAR]: luccaBoarInfo,
  [ChampionNames.JIRQO]: jirqoInfo,
  ///Meant for Champion Select
  [ChampionNames.NO_CHAMPION] : noChampion,
  [ChampionNames.FUTURE_CHAMPION_1]: futureChampion1,
  [ChampionNames.FUTURE_CHAMPION_2]: futureChampion2,
  [ChampionNames.FUTURE_CHAMPION_3]: futureChampion3,
};

export default allChampions;