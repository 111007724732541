const arrowAnimation = {
  animation: [
    // Wind Up Animation
    // {
    //   name: "shaft",
    //   duration: 1000,
    //   rotation: (0 * Math.PI) / 180,
    //   x: 0,
    //   y: 0,
    //   scale: 10.0,
    //   delay: 0,
    // },
  ],
};

export default arrowAnimation;
