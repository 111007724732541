import poisonArrowDrawInfo from "./poisonArrowDrawInfo";
import { ObjectStateConstants } from "../../../../constants/AnimationObjectConstants";

const poisonArrowInfo = {
  drawing: poisonArrowDrawInfo,
  animations: {
    [ObjectStateConstants.ACTIVE] : {animation: []}
  },
  radius: 10,
};

export default poisonArrowInfo;
