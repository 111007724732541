const bombArrowAnimation = {
  animation: [
    // Wind Up Animation
    {
      name: "explosionCircle",
      duration: 100,
      rotation: (0 * Math.PI) / 180,
      x: 0,
      y: 0,
      scale: 120.0,
      delay: 1100,
    },
  ],
};

export default bombArrowAnimation;
