import drawShapeWithAnimators from "./drawShapeWithAnimators";
import allTrails from "../trails/allTrails";
import { trailAnimationManager } from "../model/AnimationManager";
import trailManager from "../model/TrailManager";

function alignCanvasOnTrail(ctx, trail) {
  ctx.translate(trail.pos.x, trail.pos.y);
}

function unalignCanvasFromTrail(ctx, trail) {
  ctx.translate(-trail.pos.x, -trail.pos.y);
}

function renderTrail(ctx, trail) {
  let trailAnimator = trailAnimationManager.getAnimator(
    trail.id
  );
  alignCanvasOnTrail(ctx, trail);
  const trailInfo = allTrails[trail.objectName];
  for (let shape of trailInfo.drawing.shapes) {
    drawShapeWithAnimators(ctx, shape, trailAnimator);
  }
  unalignCanvasFromTrail(ctx, trail);
}

export default renderTrail;
