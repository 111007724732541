//ANIMATION CONSTANTS
//////////////////////

// const randomPositions = [
//   { x: -19, y: 16 },
//   { x: -4, y: -2 },
//   { x: -3, y: -4 },
//   { x: 5, y: -16 },
//   { x: 2, y: -15 },
//   { x: -20, y: 4 },
//   { x: 15, y: 12 },
//   { x: -6, y: -8 },
//   {x: 0, y: 0},
// ];
const randomPositions = [];
for (let i =20; i>0; i--){
    let position = {}
    let weight = i*0.2 * Math.random()

    if (i%2==0) position = {x:weight, y:weight};
    else position = {x:-weight, y:-weight};
    // position = {x:weight, y:weight};
    randomPositions.push(position)
}


const damageShakeAnimation = {
  animation: [
  ],
};

randomPositions.forEach((pos, i)=>{
    let part = {
      name: "body",
      duration: 0,
      damageShiftX: pos.x,
      damageShiftY: pos.y,
      delay: i*50,
    }
    damageShakeAnimation.animation.push(part);
})


export default damageShakeAnimation;
