import { UPDATE_USER_DIRECTION, UPDATE_MOUSE_DIRECTION } from "../constants/userInput-types";

export function updateUserDirection(direction) {
  return {
    type: UPDATE_USER_DIRECTION,
    dir: direction
  };
}

export function updateMouseDirection(radians) {
  return {
    type: UPDATE_MOUSE_DIRECTION,
    mouseDir: radians,
  };
}