import styles from "./MenuiUI.module.css"
import PAGES from "../../../constants/moba";
import { useDispatch  } from "react-redux";
import { setActivePage } from "../../../redux/actions/moba-action";
import { updatePlayerName } from "../../../redux/actions/userData-actions";
import { useState } from "react";





function MenuUI() {
  const dispatch = useDispatch();
  const [nameInput, setNameInput] = useState("");

  function onPlayerNameChange(event) {
    setNameInput(event.target.value)
  }
  function onPlayerNameEnter(event) {
    event.preventDefault();
    dispatch(updatePlayerName(nameInput));
    dispatch(setActivePage(PAGES.MENU_SCREEN_CHAMPIONS));
  }
  const onPlayButtonClick = (e) => {
    e.preventDefault();
    dispatch(updatePlayerName(nameInput));
    dispatch(setActivePage(PAGES.MENU_SCREEN_CHAMPIONS));
  };
    
    return (
      <div className={styles.container}>
        <div className={styles.UIContainer}>
          <div className={styles.statusContainer}>
            <div className={styles.statusBar}>
              <div className={styles.statusLabel}>Game Status:</div>
              <div className={styles.statusOutput}>Starting Soon!°</div>
            </div>
          </div>
          <div className={styles.playContainer}>
            <form onSubmit={onPlayerNameEnter} className={styles.playInputForm}>
              <input
                className={styles.playInput}
                value={nameInput}
                onChange={onPlayerNameChange}
                placeholder="Enter Name Here..."
              ></input>
            </form>
            <div className={styles.playButton} onClick={onPlayButtonClick}>Play</div>
          </div>
          <div className={styles.optionsContainer}>
            <div className={`${styles.option} ${styles.optionSetting}`}>Settings</div>
            <div className={styles.option}>Login</div>
            <div className={styles.option}>Signup</div>
          </div>
        </div>
      </div>
    );
}

export default MenuUI