import styles from "./SelectButton.module.css";
import { useDispatch, useSelector } from "react-redux";
import PAGES from "../../../../constants/moba";
import { setActivePage } from "../../../../redux/actions/moba-action";
import ChampionNames from "../../../../constants/ChampionNames";
import { joinGame } from "../../../../utils/networking";

function SelectButton() {
  const dispatch = useDispatch();
  const selectedChampion = useSelector((state)=>state.championSelect.selectedChampion)
  const playerName = useSelector((state)=>state.userData.playerName)
  function onSelectChampionClick() { 
    if (selectedChampion != ChampionNames.NO_CHAMPION){
      dispatch(setActivePage(PAGES.GAME_SCREEN));
      joinGame(playerName, selectedChampion);
    }
  }

  return <div onClick={onSelectChampionClick} className={styles.container}>Select Champion</div>;
}

export default SelectButton;
